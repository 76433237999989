<template>
  <div class="split-bg">
    <!--begin::Main-->
    <div class="d-flex flex-column flex-root">
      <!--begin::Login-->
      <div class="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" id="kt_login">
        <!--begin::Aside-->
        <div class="login-aside flex-row-auto p-10 p-lg-10 bg-primary d-none d-lg-flex min-vh-100 max-w-50">
          <!--begin: Aside Container-->
          <div class="d-flex flex-row-fluid flex-column justify-content-between">
            <!--begin: Aside header-->
            <a href="#" class="flex-column-auto mt-5 pb-lg-0 pb-10">
              <img :src="logo.url" class="h-70 max-h-70px" alt="" />
            </a>
            <!--end: Aside header-->
            <!--begin: Aside content-->
            <div class="flex-column-fluid d-flex flex-column justify-content-center">
              <h3 class="font-size-h1 font-weight-bolder mb-5 text-white">
                {{ $t('login.title') }}
              </h3>
              <p class="text-white opacity-80">
                {{ $t('login.sub_title') }}
              </p>
            </div>
            <!--end: Aside content-->
            <!--begin: Aside footer for desktop-->
            <div class="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
              <div class="opacity-70 font-weight-bold text-white">
                {{ new Date().getFullYear() }}{{ $t('copyright.title') }}
              </div>
              <div class="d-flex">
                <!--<a href="#" class="text-white">Политика конфиденциальности</a>-->
              </div>
            </div>
            <!--end: Aside footer for desktop-->
          </div>
          <!--end: Aside Container-->
        </div>
        <!--begin::Aside-->
        <!--begin::Content-->
        <div class="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden min-vh-100">
          <!--begin::Content header-->
          <div class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
            <span class="font-weight-bolder text-dark-50">
              {{ $t('recovery.have_account') }}
            </span>
            <a href="/signup" class="font-weight-bolder ml-2" id="kt_login_signup">
              {{ $t('recovery.register') }}
            </a>
          </div>
          <!--end::Content header-->
          <div v-if="error" class="alert alert-danger" role="alert">
            {{error}}
          </div>
          <div v-if="message" class="alert alert-success" role="alert">
            {{message}}
          </div>
          <!--begin::Content body-->
          <div class="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">

            <!--begin::Signin-->
            <div class="login-form login-signin w-100 max-w-400px">
              <div class="text-center mb-10 mb-lg-20">
                <h3 class="font-size-h1 font-weight-bolder">
                  {{ $t('recovery.recovery') }}
                </h3>
                <p class="text-muted font-weight-bolder">
                  {{ $t('recovery.hint') }}
                </p>
              </div>

              <!--begin::Form-->
              <form class="form" novalidate="novalidate" id="kt_login_signin_form"
                    ref="form" @keypress.enter.prevent="recovery">
                <div class="form-group">
                  <input class="form-control form-control-solid h-auto py-5 px-6" type="password"
                         :placeholder="$t('recovery.password')" name="password" autocomplete="off"  v-model="password"
                         v-on:keyup.enter="recovery"/>
                </div>
                <div class="form-group">
                  <input class="form-control form-control-solid h-auto py-5 px-6" type="password"
                         :placeholder="$t('recovery.repeat_password')" name="password" autocomplete="off"  v-model="repeat_password"
                         v-on:keyup.enter="recovery"/>
                </div>
                <!--begin::Action-->
                <div class="form-group d-flex flex-wrap flex-center">
                  <button v-if="!loading" @click="recovery"
                          type="button"
                          id="kt_login_forgot_submit"
                          class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">
                    {{ $t('recovery.submit') }}
                  </button>
                  <button v-if="loading" type="button" disabled
                          class="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4">
                    {{ $t('login.loading') }}
                  </button>
                  <a href="/login" type="button" id="kt_login_forgot_cancel" class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4">{{ $t('recovery.cancel') }}</a>
                </div>
                <!--end::Action-->
              </form>
              <!--end::Form-->
            </div>
            <!--end::Signin-->
          </div>
          <!--end::Content body-->
          <!--begin::Content footer for mobile-->
          <div class="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
            <div class="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">{{ new Date().getFullYear() }}{{ $t('copyright.title') }}</div>
            <!--            <div class="d-flex order-1 order-sm-2 my-2">-->
            <!--              <a href="#" class="text-dark-75 text-hover-primary">Privacy</a>-->
            <!--              <a href="#" class="text-dark-75 text-hover-primary ml-4">Legal</a>-->
            <!--              <a href="#" class="text-dark-75 text-hover-primary ml-4">Contact</a>-->
            <!--            </div>-->
          </div>
          <!--end::Content footer for mobile-->
        </div>
        <!--end::Content-->
      </div>
      <!--end::Login-->
    </div>
    <!--end::Main-->

  </div>
</template>


<script>
import {mapMutations, mapGetters} from 'vuex'
import api from '@/plugins/api'
let cookie = require('vue-cookie');

let bg = require('@/assets/bg/bg-4.jpg')

export default {
  computed: {
    ...mapGetters({
      isAuthenticated: 'user/isAuthenticated',
      token: 'user/token',
      identity: 'user/identity',
      logo: 'main/logo',
    })
  },
  data() {
    return {
      loading: false,
      bg: bg,
      password: '',
      repeat_password: '',
      message: '',
      valid: false,
      error: null,
    }
  },
  mounted() {
    console.log(this.$store.getters['user/token'])
  },
  watch: {
    token (to, from) {
      // обрабатываем изменение параметров маршрута...
      console.log("isAuthenticated", to, from)
      if (to) {
        self.$router.push('/')
      }
    }
  },
  methods: {
    goHome() {
      this.$router.push('/')
    },
    reset() {
      this.$refs.form.reset()
    },
    async recovery() {
      let self = this
      this.loading = true
      if (self.repeat_password != self.password) {
        this.loading = false
        self.error = 'Введенные пароли не совпадают'
        return undefined
      }
      let data = {
        password: self.password,
        repeat_password: self.repeat_password,
        token: self.$route.query.confirm_key
      }
      api.post('/v2/auth/change-password', data)
        .then((responce) => {
          console.log('Load data', responce.data)
          self.loading = false
          self.password = ''
          self.repeat_password = ''
          self.error = null
          self.message = this.$t('recovery.success_message')
          setTimeout(() => {
            self.$router.push('/login')
          }, 5000)
          return this
        }).catch(reason => {
          self.loading = false
          if (reason.response && reason.response.data.error.message) {
            self.error = reason.response.data.error.message;
          } else if (reason.response && reason.response.data.error) {
            self.error = reason.response.data.error;
          } else {
            self.error = this.$t('recovery.error_message')
          }
          console.log('Restore Error', response.data);
        })
      return undefined
    },
    async logout() {
      try {
        // await this.$store.dispatch('user/logout')
        self.$store.commit('user/UpdateIdentity', {});
        self.$store.commit('user/CurrentToken', null);
        cookie.delete('auth_key')
        this.$router.push('/login')
      } catch (e) {
        this.error = e.message
      }
    }
  }
}
</script>
